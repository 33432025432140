<template>
    <div>
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->

    <!-- Start Future Area -->
     <div class="service-area ptb--120 bg_color--5" id="future">
      <v-container>
        <v-row class="mb--30 mt--50">
          <v-col cols="12">
            <div class="text-center section-title">
              <!-- <span class="subtitle">Seid gespannt auf die nächste Phase</span> -->
              <h2 class="heading-title">Impressum</h2>
              <p class="description">
                Hochschule Trier - Trier University of Applied Sciences <br />
                Schneidershof <br />
                54293 Trier <br />
                <br/>
                Rechtsform: Die Hochschule Trier ist eine Körperschaft des Öffentlichen Rechts.<br/>
                Vertretung: Vertretungsberechtigter gem. § 79 HochSchG:<br/>
                Prof. Dr. Dorit Schumann (Präsidentin der Hochschule Trier)<br/>
                <br/>
                Zuständige Aufsichtsbehörde:<br/>
                Ministerium für Wissenschaft und Gesundheit des Landes Rheinland-Pfalz<br/>
                Mittlere Bleiche 61<br/>
                D-55116 Mainz<br/>
                Tel.: 0 61 31 - 16 0 (zentraler Telefondienst)<br/>
                Fax: 0 61 31 - 16 29 97<br/>
                E-Mail: poststelle(at)mwg.rlp.de<br/>
                Internet: mwg.rlp.de<br/>
                <br/>
                Inhaltlich Verantwortliche und Kontaktstelle für rechtliche Anfragen:<br/>
                Prof. Dr. Udo Burchard <br/>
                +49 651 8103-234 <br/>
                <a href="mailto:U.Burchard@hochschule-trier.de">U.Burchard@hochschule-trier.de</a><br/>
                Schneidershof | Gebäude K | Raum 201a <br/>
                54293 Trier <br/>
                <br/>
                Umsatsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>
                DE 155 681 598 <br/>
                <br/>
                Dies ist ein Studienprojekt der Veranstaltungen "Opportunity Recognition" und "Gecoachter Gründungsprozess" im Studiengang "Business Management (M.A.)" an der Fachhochschule Trier. 
                <br/>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Future Area -->
  
      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>
  
  <script>
    import Header from "../../components/ellavate/Header_ext";
    import Slider from "../../components/ellavate/Slider";
    import About from "../../components/ellavate/About";
    import Contact from "../../components/ellavate/Contact";
    import Footer from "../../components/ellavate/Footer";


    // import PortfolioTwo from "../../components/portfolio/PortfolioFour";
    // import PricingPlan from "../../components/pricing-plan/PricingPlan";
  
    export default {
      components: {
        Header,
        Slider,
        About,
        Contact,
        Footer,

        // PortfolioTwo,
        // PricingPlan,
        
      },
      data() {
        return {};
      },
    };
  </script>
  
  <style lang="scss">
    .feather-menu {
      color: #1d1d24;
    }
  </style>
  